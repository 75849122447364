import React from "react";

// Customizable Area Start
import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import moment from "moment";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import AttachFileRoundedIcon from "@material-ui/icons/AttachFileRounded";
import { Autocomplete } from "@material-ui/lab";
import NotificationsNoneRoundedIcon from "@material-ui/icons/NotificationsNoneRounded";
import DoneRoundedIcon from "@material-ui/icons/DoneRounded";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import ExpandLessRoundedIcon from "@material-ui/icons/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { ChatItem } from "../../utilities/src/interface";
import { colors } from "../../utilities/src/Colors";
import {
  deleteMoreMenuIcon,
  editMoreMenuIcon,
  icChatSearch,
  icWorkspace,
  replyIcon,
  replyMoreMenuIcon,
  sendIcon,
  xlsxFileIcon,
} from "./assets";
import CustomInput from "../../../components/src/common/CustomInput.web";
import { searchIcon } from "../../cfpromptingcustomquery1/src/assets";
import CustomDialogWithAction from "../../../components/src/common/CustomDialogWithAction.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#684EF3",
    },
    text: {
      primary: "#14101E",
    },
  },
  typography: {
    fontFamily: "'DM sans', Roboto",
    h1: {
      fontSize: "24px",
      lineHeight: "34px",
      fontWeight: 400,
    },
    body1: {
      fontSize: "14px",
      lineHeight: "21px",
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        "& .MuiSvgIcon-root": {
          color: "#14101E",
        },
        "&.Mui-disabled .MuiSvgIcon-root": {
          color: "#94A3B8",
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
        textTransform: "none",
        fontSize: "16px",
        lineHeight: "24px",
        padding: "10px 12px",
        fontWeight: 400,
      },
      contained: {
        boxShadow: "none",
        color: "#FFF",
      },
      startIcon: {
        marginLeft: "4px",
      },
    },
  },
});

const menuOptions = [
  {
    optionId: 1,
    label: "Reply",
    iconSrc: replyMoreMenuIcon,
  },
  {
    optionId: 2,
    label: "Edit",
    iconSrc: editMoreMenuIcon,
  },
  {
    optionId: 3,
    label: "Delete",
    iconSrc: deleteMoreMenuIcon,
    className: "delete__item",
  },
];

interface IMessage {
  messageId: number;
  text: string;
  sender: string;
  replyTo?: IMessage;
  timestamp: string;
  attachment?: string[];
}
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";
import ChatInfo from "./ChatInfo.web";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderChatPreview = (chatList: ChatItem[]) => (
    <List className="chat__listing">
      {chatList.map(({ id, isGroup, name, profileImage, lastMessage }) => {
        const chatPreviewName = isGroup ? this.getChatPreviewName(name) : null;

        return (
          <ListItem key={id} button className="chat__preview">
            <ListItemAvatar className="avatar__wrapper">
              <Avatar
                className="avatar"
                src={!isGroup ? profileImage : undefined}
              >
                {isGroup && (
                  <Typography className="name__avatar">
                    {chatPreviewName}
                  </Typography>
                )}
              </Avatar>
            </ListItemAvatar>

            <ListItemText
              className="message__wrapper"
              primary={
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1" className="chat__title" noWrap>
                      {name}
                    </Typography>
                    {isGroup && (
                      <img src={icWorkspace} className="icon__workspace" />
                    )}
                  </Box>
                  <Typography variant="caption" className="message__time">
                    {lastMessage.time}
                  </Typography>
                </Box>
              }
              secondary={
                <Typography variant="body2" className="message" noWrap>
                  {isGroup && lastMessage.sender && (
                    <span className="messanger__name">
                      {lastMessage.sender}:&nbsp;
                    </span>
                  )}
                  {lastMessage.text}
                </Typography>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );

  renderMessageContent = (message: IMessage, displayRepliedMessage = false) => {
    const getMessageClass = () => {
      if (displayRepliedMessage) return "reply__message more__menu";
      return message.sender === "You" ? "sender__message" : "receiver__message";
    };

    const renderMoreMenu = () => (
      <IconButton
        aria-describedby="id"
        data-test-id="more-menu"
        className="more__icon"
        size="small"
        onClick={this.openMessageMoreMonu}
      >
        <MoreVertRoundedIcon fontSize="small" />
      </IconButton>
    );

    const renderFileAttachment = () => (
      <Box className={`message ${getMessageClass()} file__wrapper`}>
        <img src={xlsxFileIcon} className="file__icon" />
        <Box>
          <Typography className="file__name">
            Project_Financial_Overview.xls
          </Typography>
          <Typography className="file__size">128 KB</Typography>
        </Box>
        {!displayRepliedMessage && renderMoreMenu()}
      </Box>
    );

    const renderImageAttachments = () => (
      <Box className={`message ${getMessageClass()}`}>
        <Box className="attachment__image__wrapper">
          {message.attachment?.map((attachment, index) => (
            <Box key={index} className="attachment__image">
              <img src={attachment} className="image" />
            </Box>
          ))}
        </Box>
        {!displayRepliedMessage && renderMoreMenu()}
      </Box>
    );

    return (
      <>
        <Box className={`message ${getMessageClass()}`}>
          <Typography>
            {displayRepliedMessage ? message.replyTo?.text : message.text}
          </Typography>
          {!displayRepliedMessage && renderMoreMenu()}
        </Box>

        {message.attachment && renderFileAttachment()}
        {message.attachment && renderImageAttachments()}
      </>
    );
  };

  renderMessages = () => {
    let prevDate = "";

    return this.state.messages.map((message) => {
      const { timestamp, sender, replyTo, messageId } = message;

      const msgMoment = moment(timestamp);
      const msgDate = msgMoment.format("YYYY-MM-DD");
      const showDivider = msgDate !== prevDate;
      prevDate = msgDate;

      const formattedTime = msgMoment.format("HH:mm A");
      const formattedDate = msgMoment.format("MMM DD");
      const isSender = sender === "You";

      return (
        <React.Fragment key={messageId}>
          {showDivider && (
            <Typography align="center" className="message__time">
              {formattedDate}
            </Typography>
          )}

          <Box
            className="message__card"
            style={{ alignItems: isSender ? "flex-end" : "flex-start", alignSelf: isSender ? "end" : "start" }}
          >
            <Typography className="message__time">{formattedTime}</Typography>
            {replyTo && this.renderMessageContent(message, true)}
            {this.renderMessageContent(message)}
          </Box>
        </React.Fragment>
      );
    });
  };

  renderProfileDrawer = () => {
    return(
      <>
        <ChatInfo 
          id="chat-info"
          navigation={this.props.navigation}
          openProfileDrawer={this.state.openProfileDrawer}
          toggleDrawer={this.toggleProfileDrawer}
        />
      </>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <MainWrapper>
          <Box className="sidebar__wrapper">
            <NavigationMenu navigation={this.props.navigation} id="dashboard" />
          </Box>

          <Box className="main__content">
            <Box className="header__wrapper">
              <Typography variant="h1" className="page__title">
                {configJSON.pageTitle}
              </Typography>
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddOutlinedIcon />}
                className="new__chat"
                data-test-id="create-new-chat"
                onClick={this.toggleCreateChat}
              >
                {configJSON.newChatLabel}
              </Button>
              <IconButton className="notification__icon">
                <NotificationsNoneRoundedIcon />
              </IconButton>
            </Box>

            <Box className="chat__content__wrapper">
              <Box className="chat__listing__wrapper">
                <Box className="search__wrapper">
                  <CustomInput
                    name="search"
                    data-test-id="search-chat-preview"
                    value={this.state.searchQuery}
                    onChange={this.handleSearch}
                    className="search__input"
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="end">
                          <img src={searchIcon} />
                        </InputAdornment>
                      ),
                      endAdornment: this.state.searchQuery && (
                        <IconButton size="small" onClick={this.resetSearch}>
                          <CloseRoundedIcon
                            className="close__btn"
                            fontSize="small"
                          />
                        </IconButton>
                      ),
                    }}
                  />
                </Box>

                {this.renderChatPreview(this.state.chatList)}
              </Box>

              <ChatWrapper>
                <Box className="chat__header__wrapper">
                  <Box className="avatar__wrapper"
                    onClick={this.toggleProfileDrawer}
                    data-test-id="profile-wrapper">
                    <Avatar
                      className="profile__pic"
                      src="https://randomuser.me/api/portraits/men/1.jpg"
                      alt="profile-pic"
                    />
                    <Box>
                      <Typography className="chat__title">
                        Cody Fisher
                      </Typography>
                      <Typography className="status">Online</Typography>
                    </Box>
                  </Box>
                  <IconButton
                    data-test-id="open-chat-message-search"
                    onClick={this.toggleChatSearch}
                  >
                    <img src={icChatSearch} alt="search" />
                  </IconButton>
                  <IconButton>
                    <MoreVertRoundedIcon />
                  </IconButton>
                </Box>

                {this.state.openChatSearch && (
                  <Box className="search">
                    <CustomInput
                      name="searchMessage"
                      fullWidth
                      className="search__input"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <img src={searchIcon} />
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography className="search__number">
                      Showing 1 of 3
                    </Typography>
                    <IconButton>
                      <ExpandLessRoundedIcon />
                    </IconButton>
                    <IconButton disabled>
                      <ExpandMoreRoundedIcon />
                    </IconButton>
                    <Box flex={1}></Box>
                    <IconButton onClick={this.toggleChatSearch}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Box>
                )}

                <Box className="chat__body__wrapper">
                  {this.renderMessages()}
                </Box>

                <Box
                  className={`chat__footer__wrapper ${
                    this.state.isEditOpen || this.state.selectedFiles.length > 0
                      ? "edit__reply__active"
                      : ""
                  }`}
                >
                  {this.state.isEditOpen && (
                    <Box className="edit__reply__content">
                      <Box>
                        <img src={replyIcon} />
                      </Box>
                      <Box flex={1}>
                        <Typography className="reply__title">
                          Reply on Cody Fisher message
                        </Typography>
                        <Typography className="reply__message">
                          Please review the attached document and the visual
                          summary.
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton
                          size="small"
                          className="close__btn"
                          data-test-id="close-edit-button"
                          onClick={this.closeEditReply}
                        >
                          <CloseRoundedIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  )}

                  {this.state.selectedFiles.length > 0 && (
                    <Box className="edit__reply__content">
                      <Box flex={1}>
                        <Box className="attachment_image__wrapper">
                          {this.state.selectedFiles
                            .filter((file) => file.type.startsWith("image"))
                            .map((file) => (
                              <Box className="image__wrapper">
                                <img
                                  src={URL.createObjectURL(file)}
                                  className="image"
                                />
                                <Box className="remove__btn">
                                  <ClearRoundedIcon fontSize="small" />
                                </Box>
                              </Box>
                            ))}
                        </Box>

                        <Box className="attachment_file__wrapper">
                          {this.state.selectedFiles
                            .filter((file) =>
                              file.type.startsWith("application")
                            )
                            .map((file) => (
                              <Box className="file__wrapper">
                                <img
                                  src={xlsxFileIcon}
                                  className="file__icon"
                                />
                                <Box flex={1}>
                                  <Typography className="file__name">
                                    {file.name}
                                  </Typography>
                                  <Typography className="file__size">
                                    {Math.round(file.size / 1000)} KB
                                  </Typography>
                                </Box>
                                <Box className="remove__btn">
                                  <ClearRoundedIcon fontSize="small" />
                                </Box>
                              </Box>
                            ))}
                        </Box>
                      </Box>
                      <Box>
                        <IconButton
                          size="small"
                          className="close__btn"
                          data-test-id="close-edit-button"
                          onClick={this.closeEditReply}
                        >
                          <CloseRoundedIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  )}

                  <Box className="input__wrapper">
                    <IconButton
                      className="file__input"
                      onClick={() => this.fileInputRef.current?.click()}
                    >
                      <AttachFileRoundedIcon />
                    </IconButton>

                    <input
                      type="file"
                      accept="image/*,application/pdf,application/msword"
                      style={{ display: "none" }}
                      ref={this.fileInputRef}
                      id="file-upload"
                      multiple
                      onChange={this.handleFileUpload}
                    />

                    <InputBase
                      className="message__input"
                      placeholder="Type message here..."
                    />

                    <IconButton className="submit__button">
                      {this.state.isEditOpen ? (
                        <DoneRoundedIcon />
                      ) : (
                        <img src={sendIcon} />
                      )}
                    </IconButton>
                  </Box>
                </Box>

                <StyledPopover
                  data-test-id="popover-menu"
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  onClose={this.closeMoreMenu}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    className: "popover__paper",
                  }}
                >
                  <List className="list">
                    {menuOptions.map((option) => (
                      <ListItem
                        data-test-id={`option-${option.label}`}
                        button
                        className={`list__item ${option.className ?? ""}`}
                        key={option.optionId}
                        onClick={() => {
                          this.closeMoreMenu();
                        }}
                      >
                        <ListItemIcon className="list__icon">
                          <img src={option.iconSrc} />
                        </ListItemIcon>
                        <ListItemText
                          primary={option.label}
                          className="list__label"
                        />
                      </ListItem>
                    ))}
                  </List>
                </StyledPopover>
              </ChatWrapper>
            </Box>
          </Box>
        </MainWrapper>

        <CustomDialogWithAction
          data-test-id="create-dialog"
          open={this.state.openCreateChat}
          handleClose={this.toggleCreateChat}
          title={configJSON.createChatTitle}
          dialogContent={
            <CreateDailogWrapper>
              <DialogContent
                style={{ width: "495px", paddingBottom: 0, minHeight: "160px" }}
                dividers
              >
                <Formik
                  initialValues={this.createChatInitialValues}
                  validationSchema={this.createChatValidationSchema}
                  onSubmit={(values) => console.log("Values: ", values)}
                >
                  {({ values, setFieldValue }) => {
                    const availableUsers = this.state.searchOptions.filter(
                      (user) =>
                        !values.members.some(
                          (selected) => selected.userId === user.userId
                        )
                    );

                    return (
                      <Form id="create-form">
                        <CustomAutoComplete
                          multiple
                          data-test-id="search-user"
                          options={availableUsers}
                          getOptionLabel={(option: any) => `${option.userName}`}
                          value={values.members}
                          loading={this.state.isSearchLoading}
                          onChange={(_, newValue) => {
                            if ((newValue as any[]).length > 0) {
                              setFieldValue("members", newValue);
                            }
                          }}
                          filterOptions={(options) => options}
                          loadingText="Loading..."
                          renderTags={() => null}
                          onInputChange={(_, newInputValue) => {
                            this.searchUserByKeyword(newInputValue);
                          }}
                          renderInput={(params) => (
                            <CustomInput
                              {...params}
                              label={configJSON.addMembersLabel}
                              name="members"
                              placeholder={configJSON.addMembersPlaceholder}
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="end">
                                    <img src={searchIcon} />
                                  </InputAdornment>
                                ),
                                endAdornment: <></>,
                              }}
                              onKeyDown={(event: any) => {
                                if (event.key === "Backspace") {
                                  event.stopPropagation();
                                }
                              }}
                            />
                          )}
                        />

                        <Box className="selected__members">
                          {values.members.map((member) => (
                            <Box className="member__wrapper" key={member.userId}>
                              <Avatar
                                className="avatar"
                                src={member.profilePic}
                              />
                              <Box className="member__details">
                                <Typography className="member__name">
                                  {member.userName}
                                </Typography>
                                <Typography className="member__email">
                                  {member.email}
                                </Typography>
                              </Box>
                              <Button
                                className="remove__btn"
                                data-test-id={`remove_${member.userId}`}
                                startIcon={
                                  <CloseRoundedIcon fontSize="small" />
                                }
                                onClick={() =>
                                  setFieldValue(
                                    "members",
                                    values.members.filter(
                                      (user) => user.userId !== member.userId
                                    )
                                  )
                                }
                              >
                                {configJSON.removeBtn}
                              </Button>
                            </Box>
                          ))}
                        </Box>

                        {values.members.length > 1 && (
                          <CustomInput
                            formik
                            name="groupName"
                            label={configJSON.addGroupLabel}
                          />
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              </DialogContent>
              <DialogActions className="create-dialog-actions">
                <Button
                  variant="outlined"
                  color="primary"
                  className="cancel__btn"
                  onClick={this.toggleCreateChat}
                >
                  {configJSON.cancelBtn}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  form="create-form"
                >
                  {configJSON.createBtn}
                </Button>
              </DialogActions>
            </CreateDailogWrapper>
          }
        />
        {this.renderProfileDrawer()}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#E2E8F0",
  height: "100vh",
  "& .sidebar__wrapper": {
    position: "sticky",
  },
  "& .main__content": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    "& > *": {
      boxSizing: "border-box",
    },
    "& .header__wrapper": {
      borderBottom: "solid 1px #E2E8F0",
      padding: "18px 40px",
      display: "flex",
      alignItems: "center",
      gap: "12px",
      backgroundColor: "#E2E8F0",
      "& .page__title": {
        flex: 1,
      },
      "& .new__chat": {
        fontWeight: 400,
        maxHeight: "34px",
      },
      "& .notification__icon": {
        marginLeft: "4px",
        padding: "10px",
      },
    },
    "& .chat__content__wrapper": {
      flex: 1,
      display: "flex",
      overflowY: "auto",
      backgroundColor: "#FFF",
      "& .chat__listing__wrapper": {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        maxWidth: "379px",
        "& .search__wrapper": {
          padding: "18px 40px",
          border: "solid 1px #E2E8F0",
          borderTop: "none",
          "& .search__input": {
            minHeight: "unset",
            "& .MuiOutlinedInput-adornedStart": {
              paddingLeft: "4px",
            },
            "& .close__btn": {
              color: "#5E6671",
              width: "16px",
              height: "16px",
            },
          },
        },
        "& .chat__listing": {
          padding: 0,
          flex: 1,
          overflowY: "auto",
          borderRight: `solid 1px ${colors().darkPurple}`,
          "& .chat__preview": {
            padding: "12px 40px",
            "& .message__wrapper": {
              margin: 0,
              "& .icon__workspace": {
                marginLeft: "8px",
              },
              "& .chat__title": {
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "21px",
              },
              "& .message__time": {
                color: colors().grayText,
                fontSize: "12px",
                lineHeight: "18px",
              },
              "& .message": {
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: 400,
                textOverflow: "ellipsis",
                color: colors().grayText,
                maxWidth: "224px",
                "& .messanger__name": {
                  color: "#14101E",
                  fontSize: "12px",
                  lineHeight: "18px",
                },
              },
            },
            "& .avatar__wrapper": {
              minWidth: "52px",
              "& .avatar": {
                height: "44px",
                width: "44px",
                backgroundColor: colors().darkPurple,
                border: `solid 3px ${colors().darkPurple}`,
                "& .name__avatar": {
                  fontWeight: 700,
                  color: colors().bluePurple,
                  fontSize: "18px",
                  lineHeight: "26px",
                },
              },
            },
          },
        },
      },
    },
  },
});

const ChatWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  "& .chat__header__wrapper": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "18px 40px",
    borderBottom: "solid 1px #E2E8F0",
    "& .avatar__wrapper": {
      flex: 1,
      display: "flex",
      alignItems: "center",
      gap: "8px",
      "& .profile__pic": {
        width: "44px",
        height: "44px",
        border: `solid 2px ${colors().darkPurple}`,
      },
      "& .chat__title": {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: 700,
      },
      "& .status": {
        fontSize: "12px",
        lineHeight: "18px",
        fontWeight: 400,
        color: colors().majorelleBlue,
      },
    },
    "& .MuiIconButton-root": {
      padding: "10px",
    },
  },
  "& .search": {
    padding: "12px 40px",
    backgroundColor: colors().tableBorder,
    display: "flex",
    alignItems: "center",
    gap: "16px",
    "& .search__input": {
      minHeight: "unset",
      maxWidth: "300px",
      "& .MuiOutlinedInput-adornedStart": {
        paddingLeft: "4px",
      },
    },
    "& .search__number": {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "21px",
    },
  },
  "& .chat__body__wrapper": {
    flex: 1,
    padding: "4px 40px 16px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    "& .message__time": {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "18px",
      color: "#5E6671",
    },
    "& .message__card": {
      maxWidth: "90%",
      display: "flex",
      gap: "6px",
      flexDirection: "column",
      "& .message": {
        borderRadius: "8px",
        padding: "8px 32px 8px 16px",
        display: "inline-block",
        position: "relative",
        "&.more__menu": {
          paddingRight: "16px",
        },
        "&.reply__message": {
          backgroundColor: "#E2E8F0B2",
          color: "#5E6671",
        },
        "&.sender__message": {
          backgroundColor: "#E3DEFF",
        },
        "&.receiver__message": {
          backgroundColor: "#F6F5F9",
        },
        "& .more__icon": {
          position: "absolute",
          top: "4px",
          right: "4px",
          "& .MuiSvgIcon-root": {
            color: "#5E6671",
          },
        },
        "& .attachment__image__wrapper": {
          display: "flex",
          flexWrap: "wrap",
          gap: "4px",
          maxWidth: "428px",
          "& .attachment__image": {
            border: "solid 2px #E2E8F0",
            borderRadius: "8px",
            aspectRatio: 1,
            overflow: "hidden",
            display: "inline-block",
            maxWidth: "104px",
            flexGrow: 1,
            "& .image": {
              width: "100%",
              height: "100%",
              objectFit: "cover",
            },
          },
        },
        "& .attachment__image__wrapper ~ .more__icon": {
          "& .MuiSvgIcon-root": {
            color: "#30353B",
          },
        },
        "&.file__wrapper": {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          padding: "8px 0px 8px 12px",
          "& .more__icon": {
            position: "relative",
            "& .MuiSvgIcon-root": {
              color: "#30353B",
            },
          },
          "& .file__name": {
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "21px",
          },
          "& .font__size": {
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 400,
          },
        },
      },
    },
  },
  "& .chat__footer__wrapper": {
    padding: "16px 12px 24px 12px",
    "&.edit__reply__active": {
      backgroundColor: colors().tableBorder,
    },
    "& .edit__reply__content": {
      display: "flex",
      gap: "8px",
      marginBottom: "12px",
      marginRight: "28px",
      "& .reply__title": {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "21px",
      },
      "& .reply__message": {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "21px",
        color: "#5E6671",
      },
      "& .close__btn": {
        alignItems: "flex-start",
        "& .MuiSvgIcon-root": {
          color: "#5E6671",
        },
      },
      "& .attachment_image__wrapper": {
        display: "flex",
        maxWidth: "428px",
        flexWrap: "wrap",
        gap: "4px",
        "& .image__wrapper": {
          aspectRatio: 1,
          border: "solid 2px #E2E8F0",
          width: "104px",
          position: "relative",
          "& .image": {
            width: "100%",
            objectFit: "cover",
            height: "100%",
            borderRadius: "8px"
          },
          "& .remove__btn": {
            position: "absolute",
            top: "-5px",
            right: "-5px",
            color: "red",
            backgroundColor: "#FFF",
            borderRadius: "50%",
            cursor: "pointer"
          },
        },
      },
      "& .attachment_file__wrapper": {
        maxWidth: "327px",
        "& .file__wrapper": {
          display: "flex",
          margin: "4px 0",
          alignItems: "center",
          "& .file__name": {
            fontSize: "14px",
            fontWeight: 700,
            lineHeight: "21px",
            textOverflow: "ellipsis"
          },
          "& .font__size": {
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: 400,
          },
        }
      }
    },
    "& .input__wrapper": {
      margin: "0 28px",
      display: "flex",
      alignItems: "center",
      padding: "6px 12px 6px 6px",
      border: "solid 1px #E2E8F0",
      borderRadius: "12px",
      backgroundColor: "#FFF",
      "& .file__input": {
        padding: "10px",
        "& .MuiSvgIcon-root": {
          color: "#94A3B8",
        },
      },
      "& .message__input": {
        flex: 1,
        fontSize: "16px",
        lineHeight: "24px",
        marginRight: "10px",
      },
      "& .submit__button": {
        borderRadius: "8px",
        border: "none",
        backgroundColor: "#684EF3",
        padding: "10px",
        height: "44px",
        width: "44px",
        "& .MuiSvgIcon-root": {
          color: "#FFF",
        },
      },
    },
  },
});

const StyledPopover = styled(Popover)({
  "& .popover__paper": {
    borderRadius: "8px",
    padding: "4px",
    border: "solid 1px #E2E8F0",
    boxShadow: "0px 4px 6px -2px #684EF333",
  },
  "& .list": {
    paddingTop: 0,
    paddingBottom: 0,
    "& .list__item": {
      maxHeight: "36px",
      width: "104px",
      "& .list__icon": {
        marginRight: "10px",
        minWidth: "unset",
      },
      "& .list__label": {
        color: "#30353B",
        lineHeight: "21px",
        fontSize: "14px",
      },
      "&.delete__item": {
        "& .list__label": {
          color: "red",
        },
      },
    },
  },
});

const CreateDailogWrapper = styled(Box)({
  "& .create-dialog-actions": {
    justifyContent: "unset",
    "& > *": {
      flex: 1,
    },
    "& .cancel__btn": {
      maxWidth: "104px",
      padding: "10px 12px",
    },
  },
  "& .selected__members": {
    display: "flex",
    marginBottom: "28px",
    flexDirection: "column",
    maxHeight: "144px",
    overflowY: "auto",
    "& .member__wrapper": {
      display: "flex",
      gap: "12px",
      padding: "12px",
      backgroundColor: "#F6F5F9",
      borderRadius: "8px",
      alignItems: "center",
      "& .avatar": {
        width: "44px",
        height: "44px",
        border: `solid 2px ${colors().darkPurple}`,
      },
      "& .member__details": {
        flex: 1,
        "& .member__name": {
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "21px",
        },
        "& .member__email": {
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "21px",
          color: "#0F172A",
        },
      },
      "& .remove__btn": {
        maxWidth: "93px",
        maxHeight: "24px",
        backgroundColor: "#FFFFFF",
        border: "none",
        borderRadius: "26px",
        color: "#30353B",
        fontSize: "14px",
        lineHeight: "22px",
        padding: "1px 12px 1px 4px",
        "& .MuiSvgIcon-root": {
          width: "14px",
          height: "14px",
        },
      },
    },
  },
});

const CustomAutoComplete = styled(Autocomplete)({
  "& .MuiFormControl-root": {
    "& .MuiInputBase-root": {
      padding: "0 0 0 2px !important",
    },
  },
});

// Customizable Area End
