import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { createRef } from "react";
import { ChatItem } from "../../utilities/src/interface";
import * as Yup from "yup";
import { businessAttachment } from "./assets";
import { makeApiMessage, navigateToHomeAsTokenExpire } from "../../utilities/src/commonfunctions";
import { handleResponseMessage } from "../../utilities/src/handle-response-message";
import { SearchSuccessResponse } from "./components/types";

interface Member {
  userId: string;
  userName: string;
  email: string;
  profilePic: string;
}

interface IMessage {
  messageId: number;
  sender: string;
  text: string;
  timestamp: string;
  attachment?: string[];
  replyTo?: IMessage;
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  chatList: ChatItem[];
  searchQuery: string;
  openCreateChat: boolean;
  openChatSearch: boolean;
  isEditOpen: boolean;
  messages: IMessage[];
  anchorEl: HTMLButtonElement | null;
  selectedFiles: File[];
  searchOptions: {
    userId: string,
    userName: string,
    email: string,
    profilePic: string
  }[],
  isSearchLoading: boolean;
  openProfileDrawer: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  searchUserByKeywordApiCallId: string = "";
  fileInputRef: React.RefObject<HTMLInputElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      chatList: [],
      searchQuery: "",
      openCreateChat: false,
      openChatSearch: false,
      isEditOpen: false,
      messages: [
        {
          messageId: 1,
          sender: "Cody Fisher",
          text: "Let me know if there's anything that needs tweaking.",
          timestamp: "2025-02-23T12:35:00.000Z",
        },
        {
          messageId: 2,
          sender: "You",
          text:
            "Thanks, Cody. I'll dive into the details and get back to you if I have any questions.",
          timestamp: "2025-02-23T12:36:00.000Z",
          replyTo: {
            messageId: 1,
            sender: "Cody Fisher",
            text: "Let me know if there's anything that needs tweaking.",
            timestamp: "2025-02-23T12:35:00.000Z",
          },
        },
        {
          messageId: 3,
          sender: "Cody Fisher",
          text: "Here are the documents.",
          timestamp: "2025-02-24T10:00:00.000Z",
          attachment: [
            businessAttachment,
            businessAttachment,
            businessAttachment,
            businessAttachment,
            businessAttachment,
          ],
        },
        {
          messageId: 4,
          sender: "You",
          text: "Got it, thanks!",
          timestamp: "2025-02-24T10:05:00.000Z",
          attachment: [
            businessAttachment,
            businessAttachment,
            businessAttachment,
            businessAttachment,
            businessAttachment,
          ],
        },
      ],
      anchorEl: null,
      selectedFiles: [],
      searchOptions: [],
      isSearchLoading: false,
      openProfileDrawer: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.fileInputRef = createRef<HTMLInputElement>();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    const apiCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const errorResponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (errorResponse) this.parseApiCatchErrorResponse(errorResponse);
    if (responseJson?.errors?.[0].token === "Token has Expired" || responseJson?.errors?.[0].token === "Invalid token") {
      navigateToHomeAsTokenExpire(this.props);
    }

    if(apiCallId && responseJson) {
      if(apiCallId === this.searchUserByKeywordApiCallId) {
        handleResponseMessage({
          responseJson: responseJson,
          errorJson: errorResponse,
          onSuccess: () => {
            const results = (responseJson as SearchSuccessResponse);

            if(results.meta.count) {
              const preparedList = 
                results.data.map(user => ({
                  userId: user.id,
                  userName: user.attributes.first_name || "",
                  email: user.attributes.email,
                  profilePic: user.attributes.profile_photo || ""           
                }))
              this.setState({ searchOptions: preparedList, isSearchLoading: false })
            } else {
              this.setState({ searchOptions: [], isSearchLoading: false })
            }
          },
          onFail: () => {
            this.setState({ searchOptions: [], isSearchLoading: false })
          },
        })
      }
    }

  }

  async componentDidMount() {
    this.setState({
      chatList: [
        {
          id: 1,
          name: "Developers",
          isGroup: true,
          lastMessage: {
            sender: "Paul Smith",
            text: "Thank you for sharing the designs",
            time: "1m",
          },
        },
        {
          id: 2,
          name: "LT Workspace",
          isGroup: true,
          lastMessage: {
            sender: "Paul Smith",
            text: "Thank you for sharing the d...",
            time: "1m",
          },
        },
        {
          id: 3,
          name: "Top Designers",
          isGroup: true,
          lastMessage: {
            sender: "Paul Smith",
            text: "Thank you for sharing the d...",
            time: "32m",
          },
        },
        {
          id: 4,
          name: "Cody Fisher",
          isGroup: false,
          profileImage: "https://randomuser.me/api/portraits/men/1.jpg",
          lastMessage: {
            sender: "",
            text: "Haha that's terrifying ",
            time: "1h",
          },
        },
        {
          id: 5,
          name: "Deeksha",
          isGroup: false,
          profileImage: "https://randomuser.me/api/portraits/women/1.jpg",
          lastMessage: {
            sender: "",
            text: "Thank you for sharing the designs with me",
            time: "1h",
          },
        },
        {
          id: 6,
          name: "Administration",
          isGroup: true,
          lastMessage: {
            sender: "Paul Smith",
            text: "Thank you for sharing the de...",
            time: "2h",
          },
        },
        {
          id: 7,
          name: "Project Team",
          isGroup: true,
          lastMessage: {
            sender: "Paul Smith",
            text: "Thank you for sharing the de...",
            time: "2h",
          },
        },
        {
          id: 8,
          name: "John Doe",
          isGroup: false,
          profileImage: "https://randomuser.me/api/portraits/men/2.jpg",
          lastMessage: {
            sender: "",
            text: "Thank you for sharing the designs with...",
            time: "3h",
          },
        },
        {
          id: 9,
          name: "Sales",
          isGroup: true,
          lastMessage: {
            sender: "Paul Smith",
            text: "Thank you for sharing the des...",
            time: "1d",
          },
        },
        {
          id: 10,
          name: "Arya Stark",
          isGroup: false,
          profileImage: "https://randomuser.me/api/portraits/women/2.jpg",
          lastMessage: {
            sender: "",
            text: "Thank you for sharing the designs with me",
            time: "1d",
          },
        },
        {
          id: 11,
          name: "Charlotte Novak",
          isGroup: false,
          profileImage: "https://randomuser.me/api/portraits/women/3.jpg",
          lastMessage: {
            sender: "",
            text: "Hey, you're right. I'll check this out",
            time: "1d",
          },
        },
        {
          id: 12,
          name: "Arya Stark",
          isGroup: false,
          profileImage: "https://randomuser.me/api/portraits/women/2.jpg",
          lastMessage: {
            sender: "",
            text: "Thank you for sharing the designs with me",
            time: "1d",
          },
        },
        {
          id: 13,
          name: "Charlotte Novak",
          isGroup: false,
          profileImage: "https://randomuser.me/api/portraits/women/3.jpg",
          lastMessage: {
            sender: "",
            text: "Hey, you're right. I'll check this out",
            time: "1d",
          },
        },
      ],
    });
  }

  createChatValidationSchema = Yup.object().shape({
    members: Yup.array()
      .min(1, "At least one member is required.")
      .required("At least one member is required."),
    groupName: Yup.string().when("members", {
      is: (members) => members.length > 1,
      then: Yup.string().required(
        "Group name is required when more than one member is selected."
      ),
    }),
  });

  createChatInitialValues = {
    members: [] as Member[],
    groupName: "",
  };

  getChatList = async (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMyChatsApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getChatPreviewName = (name: string): string => {
    const nameArr = name.trim().split(/\s+/);
    return nameArr.length > 1
      ? `${nameArr[0][0]}${nameArr[1][0]}`
      : nameArr[0][0];
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ searchQuery: value });
  };

  resetSearch = () => this.setState({ searchQuery: "" });

  toggleCreateChat = () =>
    this.setState((prevState) => ({
      openCreateChat: !prevState.openCreateChat,
    }));

  toggleChatSearch = () =>
    this.setState((prevState) => ({
      openChatSearch: !prevState.openChatSearch,
    }));

  closeEditReply = () =>
    this.setState({ isEditOpen: false, selectedFiles: [] });

  openMessageMoreMonu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMoreMenu = () => {
    this.setState({ anchorEl: null });
  };

  handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      this.setState((prevState) => ({
        selectedFiles: [
          ...prevState.selectedFiles,
          ...Array.from(event.target.files as FileList),
        ],
      }));
    }
  };

  searchUserByKeyword = async (query: string) => {
    this.setState({ isSearchLoading: true });

    const requestMessage = await makeApiMessage({
      url: (configJSON.searchUserApiEndPoint as string).concat(query),
      method: "GET",
    });

    this.searchUserByKeywordApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  toggleProfileDrawer = () => {
    this.setState({ openProfileDrawer: !this.state.openProfileDrawer });
  }

  // Customizable Area End
}
