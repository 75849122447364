export const docImg = require("../assets/docImg.png");
export const movImg = require("../assets/movImg.png");
export const pdfImg = require("../assets/pdfImg.png"); 
export const dwnlod = require("../assets/dwnlod.png");
export const upload = require("../assets/upload.png"); 
export const movFile = require("../assets/movFile.png");
export const pdfFile = require("../assets/pdfFile.png");
export const docFile = require("../assets/docFile.png");
export const sampleFile = require("../assets/sampleFile.png");
export const avatar = require("../assets/postavatar.png");
export const uploadSvgIcon = require("../assets/uploadsvg.svg");



